/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, Input, InputNumber, Popover, Divider, Select, Form, Space, Upload } from 'antd'
import styles from './style.module.scss'
import {
	addBrand,
	addVehicle,
	deleteUser,
	deleteVehicle,
	getBrand,
	getBrands,
	getVehicleList,
	getVehicleStatus,
	logOut,
	sendReport,
	updateOperatingMode,
} from '@services/api'
import useEventSource from '@hooks/useSource'
import { DownOutlined, PlusCircleOutlined, DeleteOutlined, LogoutOutlined, UploadOutlined } from '@ant-design/icons'

import {
	DividerComponent,
	CarIcon,
	ChargerIcon,
	ChargeIconActive,
	ClockIcon,
	BatteryIcon,
	OptimizationIcon,
	OptimizationCharger,
	ReportButton,
	PlugCcsDcSIcon,
} from './components'
import { HPCIcon } from './components/HPCIcon'

const AccountTab = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm()

	const [vehiclesList, setList] = useState([])
	const [showAgreeModal, setShowAgreeModal] = useState(false)
	const [selected, setSelected] = useState(null)
	const [isControlled, setIsControlled] = useState(false)
	const [showConfirmation, setConfirmation] = useState(null)
	const [showAdd, setShowAdd] = useState(false)
	const [newVehicle, setNewVehicle] = useState({})
	const [error, setError] = useState('')
	const [currentVehicle, setCurrentVehicle] = useState({})
	const [vehicleObj, setVehicleObj] = useState({})
	const [activeStep, setActiveStep] = useState('week')
	const [vehiclesIds, setVehiclesIds] = useState([])
	const [initialChangeDone, setInitialChangeDone] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showSelectBrand, setShowSelectBrand] = useState(false)
	const [selectedBrand, setSelectedBrand] = useState('')
	const [brandList, setBrandList] = useState([])
	const [loginUrl, setLoginUrl] = useState('')
	const [token, setToken] = useState('')
	const [isDisabledAddVehicle, setIsDisabledAddVehicle] = useState(true)
	const [openReportModal, setOpenReportModal] = useState(false)
	const [file, setFile] = useState(null)

	const {
		id,
		actualSoc,
		balance,
		charging,
		chargingETA,
		chargingUsingDC,
		fullyCharged,
		pluggedIn,
		saving,
		setId,
		targetSoc,
	} = useEventSource()

	const fetchVehicles = useCallback(async () => {
		const response = await getVehicleList()
		if (response) {
			const data = response.map((vehicle) => getVehicleStatus(vehicle.id))
			const updatedList = await Promise.all(data)
			const dataMap = response.map((vehicle, index) => ({
				...vehicle,
				...updatedList[index],
			}))
			setList(dataMap)
			setVehiclesIds(response.map((vehicle) => vehicle.id))
		}
	}, [])

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		if (searchParams.has('code')) {
			setShowAdd(true)
			setToken(searchParams.get('code'))
		}
	}, [])

	useEffect(() => {
		const fetchBrands = async () => {
			const response = await getBrands()
			setBrandList(response || [])
		}

		fetchBrands()
	}, [])

	useEffect(() => {
		if (
			newVehicle.name &&
			newVehicle.vin &&
			Number.isFinite(newVehicle.batteryCapacity) &&
			newVehicle.batteryCapacity >= 0
		) {
			setIsDisabledAddVehicle(false)
		} else {
			setIsDisabledAddVehicle(true)
		}
	}, [newVehicle])

	useEffect(() => {
		fetchVehicles()
	}, [fetchVehicles])

	useEffect(() => {
		changeVehicleObj()
	}, [charging, chargingETA, fullyCharged, pluggedIn, actualSoc, targetSoc, id])

	useEffect(() => {
		if (vehiclesList.length > 0 && vehiclesIds.length > 0 && !initialChangeDone) {
			onChangeVehicle(vehiclesIds[0])
			setInitialChangeDone(true)
		}
	}, [vehiclesList, vehiclesIds, initialChangeDone])

	useEffect(() => {
		setIsControlled(currentVehicle?.controlMode === 'CONTROLLED')
	}, [currentVehicle])

	useEffect(() => {
		if (initialChangeDone) {
			onChangeVehicle(selected)
		}

		if (vehiclesList?.length) {
			const newSelected = vehiclesList[0]?.id
			onChangeVehicle(newSelected)
		} else {
			onChangeVehicle(null)
		}
	}, [vehiclesList])

	const onChangeVehicle = async (id) => {
		setId(id)
		setSelected(id)
		setCurrentVehicle(...vehiclesList.filter((e) => e.id === id))
	}
	const changeVehicleObj = () => {
		setVehicleObj(() => ({
			actualSoc,
			charging,
			chargingETA,
			chargingUsingDC,
			fullyCharged,
			pluggedIn,
			targetSoc,
		}))
	}
	const chargingStatus = (charging, fullyCharged) => {
		let status
		if (charging) {
			status = 'charging'
		} else if (!charging && !fullyCharged) {
			status = 'not charging'
		} else if (fullyCharged) {
			status = 'fully charged'
		} else {
			status = 'N/A'
		}
		return status
	}

	const logoutHandler = async () => {
		try {
			await logOut()
			localStorage.clear()
		} catch (error) {
			console.log(error)
		}
		localStorage.clear()
		window.location.href = '/'
	}

	const handleDelete = async () => {
		await deleteUser()
		window.location.href = '/'
		localStorage.clear()
	}

	const handleControlMode = async (id, value) => {
		try {
			await updateOperatingMode(id, isControlled)
			await fetchVehicles()
			onChangeVehicle(id)
		} catch (error) {
			console.error('Error updating operating mode:', error)
		}
		setConfirmation(null)
	}

	const handleSwitch = () => {
		setIsControlled((prev) => !prev)
		setConfirmation({
			value: isControlled,
			id: currentVehicle?.id,
		})
	}

	const handleCancel = () => {
		setConfirmation(null)
		setIsControlled((prev) => !prev)
	}

	const handleDeleteVehicle = async (id) => {
		await deleteVehicle(id)
		await fetchVehicles()
	}

	const handleAddVehicle = async () => {
		const brand = await getBrand()

		const data = {
			name: newVehicle.name,
			vin: newVehicle.vin,
			batteryCapacity: newVehicle.batteryCapacity,
			brand,
			additionalProperties: {
				VCF_REGISTRATION_TOKEN: token,
			},
		}

		await addVehicle(data)
		await fetchVehicles()
		setShowAdd(false)
		navigate('/account', { replace: true })
	}

	const handleSelectBrand = (value, option) => {
		addBrand(option?.label)
		setLoginUrl(value.split(' ')[1])
		setSelectedBrand(option?.label)
	}

	const handleLoginBrand = () => {
		window.location.href = loginUrl
	}

	const formItemLayout = {
		labelCol: {
			span: 6,
		},
		wrapperCol: {
			span: 14,
		},
	}

	const handleChangeFile = (info) => {
		setFile(info.fileList[0]?.originFileObj || null)
	}
	const onFinish = async (values) => {
		const formData = new FormData()
		formData.append('feedback', new Blob([JSON.stringify({ message: values.report })], { type: 'application/json' }))
		formData.append('image', values?.file[0]?.originFileObj)

		sendReport(formData)
		form.resetFields()
		setOpenReportModal(false)
	}

	const openPrivacyPolicy = () => {
		window.open('/Datenschutzerklärung - Etwicklung Gesteuertes Laden-VFinal.pdf','popup', '_blank')
	}

	return (
		<div className={styles.chargerBevContainer}>
			<Popover title={<h2 align="center">Logout</h2>}>
				<Button
					icon={<LogoutOutlined style={{ fontSize: '28px' }} />}
					onClick={() => {
						logoutHandler()
					}}
					className={styles.desktopLogoutButton}
				/>
			</Popover>
			<div className={styles.mobileLogoutContainer}>
				<Button
					icon={<LogoutOutlined style={{ fontSize: '28px' }} />}
					onClick={() => {
						logoutHandler()
					}}
					className={styles.mobileLogoutButton}
				>
					Log Out
				</Button>
			</div>

			<div className={styles.bevOptions}>
				<Popover title={<h2 align="center">Delete Vehicle</h2>}>
					<div className={styles.bevIcons}>
						<DeleteOutlined onClick={() => handleDeleteVehicle(selected)} />
					</div>
				</Popover>
				<div style={{ position: 'relative' }}>
					<Select
						size="large"
						suffixIcon={<DownOutlined className={styles.dropDownIcon} />}
						className={styles.bevDropdown}
						placeholder="Select Vehicle"
						value={currentVehicle?.name}
						showSearch={false}
						onChange={onChangeVehicle}
						options={vehiclesList.map((item) => ({
							label: item.name,
							value: item.id,
						}))}
						variant="borderless"
					/>
				</div>
				<div className={styles.bevIcons}>
					<PlusCircleOutlined onClick={() => setShowAgreeModal(true)} />
				</div>
			</div>

			<div
				className={[
					styles.topCharger,
					vehicleObj.pluggedIn ? styles.topChargerActive : '',
					!vehicleObj.pluggedIn && !vehicleObj.charging ? styles.topChargerInActive : '',
				].join(' ')}
			>
				<CarIcon charging={vehicleObj.charging} isPlug={vehicleObj.pluggedIn} isDc={vehicleObj.chargingUsingDC} />
				{vehicleObj.chargingUsingDC && (
					<div className={styles.plugCcsDcContainer}>
						<PlugCcsDcSIcon fill="#f37037" className={styles.plugCcsDc} />
						<HPCIcon fill="#f37037" className={styles.dcCharging} />
					</div>
				)}
				{vehicleObj.pluggedIn ? (
					<ChargeIconActive
						isCharging={vehicleObj.charging}
						isPlug={vehicleObj.pluggedIn}
						isDc={vehicleObj.chargingUsingDC}
					/>
				) : (
					<ChargerIcon />
				)}
			</div>

			<hr />

			<div className={styles.timeCharger}>
				<div className={styles.blockCharger}>
					<span>{vehicleObj.actualSoc}%</span>
					<BatteryIcon capacity={vehicleObj.actualSoc} isCharging={vehicleObj.charging} isPlug={vehicleObj.pluggedIn} />
				</div>
				<div className={styles.blockCharger}>
					{vehicleObj.pluggedIn && (
						<span className={styles.green}>{chargingStatus(vehicleObj.charging, vehicleObj.fullyCharged)}</span>
					)}
					<DividerComponent
						actual={vehicleObj.actualSoc}
						isCharging={vehicleObj.charging}
						isPlug={vehicleObj.pluggedIn}
						target={vehicleObj.targetSoc}
						chargingEst={vehicleObj.chargingETA}
					/>
				</div>
				<div className={styles.iconClockContainer}>
					<ClockIcon className={vehicleObj.pluggedIn ? styles.clockIcon : styles.hidden} />
				</div>
			</div>
			<Divider className={styles.dividerCustom} />
			{<OptimizationCharger daily={saving[activeStep]} />}
			<Divider className={styles.dividerCustom} />
			<div className={styles.saveCharger}>
				<div className={styles.informationCharger}>
					<span>{balance[activeStep] ?? 0}%</span>
					<OptimizationIcon />
				</div>
				<div className={styles.stepper}>
					<span className={activeStep === 'week' ? styles.active : ''} onClick={() => setActiveStep('week')}>
						week
					</span>
					<span className={activeStep === 'month' ? styles.active : ''} onClick={() => setActiveStep('month')}>
						month
					</span>
					<span className={activeStep === 'year' ? styles.active : ''} onClick={() => setActiveStep('year')}>
						year
					</span>
				</div>
			</div>
			<Divider className={styles.bottomDivider} />
			<Button className={[styles.chargeNow, styles.active].join(' ')} onClick={handleSwitch}>
				{isControlled ? `Charge now > 100%` : 'Activate optimized charging'}
			</Button>
			<Divider className={styles.bottomDivider} />
			<div className={styles.buttonContainer}>
				<Button className={styles.deleteButton} type="primary" onClick={() => setShowDeleteModal(true)}>
					Delete Pool-System Account
				</Button>
				<Button className={styles.termsButton} type="primary" onClick={openPrivacyPolicy}>
					Privacy Policy (Datenschutzerklärung)
				</Button>
			</div>

			<Modal
				open={showConfirmation}
				onOk={() => handleControlMode(currentVehicle?.id, showConfirmation.value)}
				onCancel={handleCancel}
				okText={'Confirm'}
				cancelText={'Cancel'}
				centered
			>
				<p>
					{isControlled
						? 'Are you sure you want to take part in optimized charging as part of the Audi Gigaschwarm?'
						: 'Are you sure that you want to set your target SoC to 100% and therefore no optimized charging will take place until the next plugging in/charging process or until you reactivate optimized charging?'}
				</p>
			</Modal>

			<Modal
				cancelText={'Cancel'}
				centered
				okText={'Agree'}
				onCancel={() => {
					setShowAgreeModal(false)
				}}
				onOk={() => {
					setShowAgreeModal(false)
					setShowSelectBrand(true)
				}}
				open={showAgreeModal}
			>
				<p>Do you agree that your data will be used by the pooling system?</p>
			</Modal>

			<Modal
				cancelText={'Cancel'}
				centered
				disable
				okButtonProps={{ disabled: isDisabledAddVehicle }}
				okText={'Add'}
				onCancel={() => setShowAdd(false)}
				onOk={handleAddVehicle}
				open={showAdd}
				title={'Add New Vehicle'}
			>
				<div className={styles.formContainer}>
					<span className={styles.formLabel}>Name</span>
					<Input onChange={(e) => setNewVehicle((prev) => ({ ...prev, name: e.target.value }))} />
					<span className={styles.formLabel}>VIN</span>
					<Input
						status={error ? 'error' : ''}
						onChange={(e) => setNewVehicle((prev) => ({ ...prev, vin: e.target.value }))}
					/>
					<span className={styles.error}>{error}</span>
					<span className={styles.formLabel}>Battery Capacity</span>
					<InputNumber min={0} onChange={(value) => setNewVehicle((prev) => ({ ...prev, batteryCapacity: value }))} />
				</div>
			</Modal>

			<Modal
				title={'Delete Pool-System Account'}
				open={showDeleteModal}
				centered
				onCancel={() => setShowDeleteModal(false)}
				onOk={handleDelete}
				okText={'Delete'}
			>
				<p>Are you sure you want to delete your Pool-System Account?</p>
			</Modal>

			<Modal
				centered
				okText={'Login'}
				onCancel={() => {
					setLoginUrl('')
					setSelectedBrand('')
					setShowSelectBrand(false)
				}}
				onOk={handleLoginBrand}
				okButtonProps={{ disabled: !loginUrl }}
				open={showSelectBrand}
				title={'Select Brand'}
			>
				<Select
					onChange={handleSelectBrand}
					options={brandList?.map((item, index) => ({
						label: item.brand,
						value: `${index} ${item.loginUrl}`,
					}))}
					placeholder="Select Brand"
					showSearch={false}
					style={{ width: '100%' }}
					value={selectedBrand}
				/>
			</Modal>

			<Modal
				centered
				footer={null}
				onCancel={() => {
					form.resetFields()
					setOpenReportModal(false)
				}}
				open={openReportModal}
				title={'Report an issue form'}
			>
				<Form
					{...formItemLayout}
					form={form}
					name="report"
					onFinish={onFinish}
					style={{
						maxWidth: 600,
					}}
				>
					<Form.Item
						name="report"
						label="Report"
						rules={[
							{
								required: true,
								message: 'Please input report',
							},
						]}
					>
						<Input.TextArea showCount maxLength={1000} />
					</Form.Item>
					<Form.Item name="file" label="Upload" valuePropName="fileList" getValueFromEvent={(e) => e?.fileList || []}>
						<Upload
							accept="image/png, image/jpeg"
							maxCount={1}
							beforeUpload={() => false}
							fileList={file ? [file] : []}
							onChange={handleChangeFile}
						>
							<Button icon={<UploadOutlined />}>Click to upload</Button>
						</Upload>
					</Form.Item>
					<Space>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button htmlType="reset">Reset</Button>
					</Space>
				</Form>
			</Modal>

			<ReportButton onClick={setOpenReportModal} />
		</div>
	)
}

export default AccountTab
